import { RumEvent } from '@datadog/browser-rum';
import {
  DEFAULT_404_MESSAGE,
  DEFAULT_FORBIDDEN_MESSAGE,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_UNAUTHORIZED_MESSAGE,
} from '../constants/ErrorsConstants';
import ErrorService from '../services/ErrorService';
import { ErrorCode } from '../types';
import { isForceReportingError } from './TypeUtils';
import Logger from './Logger';

const IGNORE_ERROR_MESSAGES = [
  'Network Error',
  'Request aborted',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'timeout of 0ms exceeded',
  'Cannot redefine property: googletag',
];

export const getErrorMessage = (
  errorCode: ErrorCode,
  resourceName?: string,
) => {
  if (errorCode === ErrorCode.UNAUTHORIZED) {
    return `${DEFAULT_UNAUTHORIZED_MESSAGE} ${resourceName}.`;
  }

  if (errorCode === ErrorCode.FORBIDDEN) {
    return `${DEFAULT_FORBIDDEN_MESSAGE} ${resourceName}.`;
  }

  if (errorCode === ErrorCode.NOT_FOUND) {
    return DEFAULT_404_MESSAGE;
  }

  return DEFAULT_SERVER_ERROR_MESSAGE;
};

export const getCommonsApiErrorMessage = (e: any) =>
  e.response?.data['com.real.commons.apierror.ApiError']?.message ||
  getErrorMessage(ErrorService.getErrorCode(e));

export const isDatadogErrorEventValid = (event: RumEvent): boolean => {
  const error = event.error as Error;
  if (isForceReportingError(error)) {
    Logger.debug('Error is set to force reporting. Reporting to Datadog.');
    return true;
  }

  try {
    const isWebkitMaskedUrl = error?.stack?.includes('webkit-masked-url');

    if (isWebkitMaskedUrl) {
      return false;
    }
  } catch (e) {
    Logger.warn("Couldn't check if error is from LaunchNotes. Continuing...");
  }

  const message = error?.message ?? '';

  const includesIgnorableMessage = IGNORE_ERROR_MESSAGES.find((ignoreMessage) =>
    message.includes(ignoreMessage),
  );

  return !includesIgnorableMessage;
};
